<template>
<div>
  <v-snackbar
    v-if="visible"
    v-model="visible"
    :color="color"
    :timeout="3000"
    elevation="0"
    class="inline-snackbar"
  >
    {{ message }}
    <template #actions>
      <v-btn
        color="white"
        text
        @click="visible = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'ShowNotification',
  setup() {
    const store = useStore();

    const visible = computed({
      get: () => store.state.notification.visible,
      set: (value) => {
        if (!value) {
          store.dispatch('notification/hideNotification');
        }
      },
    });
    const message = computed(() => store.state.notification.message);
    const color = computed(() => store.state.notification.color);

    return {
      visible,
      message,
      color,
    };
  },
};
</script>

<style scoped>
.inline-snackbar {
  position: relative;
  transform: none;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px
}
</style>