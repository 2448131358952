import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import {setAuthToken} from '@/services/api'
import validationMixin from './mixins/validation'; // Import the mixin


loadFonts()

const token = localStorage.getItem('token');
if (token) {
    setAuthToken(token);
    store.commit('setAuthentication', {
        isAuthenticated: true,
        userRoles: store.state.userRoles,
        token: token,
        userProfile: store.state.userProfile,
    });
}

createApp(App)
    .use(store)
    .use(router)
    .use(vuetify)
    .mixin(validationMixin)
    .mount('#app');
