import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import LoginView from '@/views/LoginView.vue';
import DashboardView from '@/views/DashboardView.vue';
import ManajemenKamar from '@/views/ManajemenKamar.vue';
import ManajemenPengguna from '@/views/ManajemenPengguna.vue';
import ManajemenPengeluaran from '@/views/ManajemenPengeluaran.vue';
import Laporan from '@/views/LaporanView.vue';
import DetailEditKamar from '@/views/DetailEditKamar.vue';
import ManajemenPenghuni from '@/views/ManajemenPenghuni.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardView,
    meta: { requiresAuth: true, allowedRoles: ['owner', 'receptionist'] },
  },
  {
    path: '/manajemen-kamar',
    name: 'ManajemenKamar',
    component: ManajemenKamar,
    meta: { requiresAuth: true, allowedRoles: ['owner', 'receptionist'] },
  },
  {
    path: '/manajemen-pengguna',
    name: 'ManajemenPengguna',
    component: ManajemenPengguna,
    meta: { requiresAuth: true, allowedRoles: ['owner'] }, // Only owner can access
  },
  {
    path: '/manajemen-pengeluaran',
    name: 'ManajemenPengeluaran',
    component: ManajemenPengeluaran,
    meta: { requiresAuth: true, allowedRoles: ['owner'] },
  },
  {
    path: '/laporan',
    name: 'Laporan',
    component: Laporan,
    meta: { requiresAuth: true, allowedRoles: ['owner'] }, // Only owner can access
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path : '/detail-edit-kamar/:roomId',
    name : 'DetailEditKamar',
    component: DetailEditKamar,
    meta: { requiresAuth: true, allowedRoles: ['owner', 'receptionist'] },
    props : true,
  },
  {
    path: '/manajemen-penghuni',
    name: 'ManajemenPenghuni',
    component: ManajemenPenghuni,
    meta: { requiresAuth: true, allowedRoles: ['owner', 'receptionist'] }, // Only owner can access
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


// Enhanced Navigation Guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;
  const userRoles = store.getters.userRoles; // Now an array

  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      next('/login');
    } else if (to.meta.allowedRoles && !to.meta.allowedRoles.some((role) => userRoles.includes(role))) {
      alert('Access denied: You do not have permission to view this page.');
      next(false); // Stay on the current page
    } else {
      next();
    }
  } else if (to.path === '/login' && isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;