<template>
  <v-container>
    <v-row
      ><v-col cols="auto"> <h1>Manajemen Pengeluaran</h1> </v-col></v-row
    >
    <v-row justify="space-between">
      <v-col cols="auto">
        <v-responsive class="mx-auto" min-width="300" max-width="400">
          <!-- Search Field -->
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Cari Tipe/Deskripsi"
            single-line
            @input="searchExpenses"
            variant="outlined"
            density="compact"
          ></v-text-field>
        </v-responsive>
      </v-col>

      <v-col cols="auto">
        <v-btn
          color="primary"
          append-icon="mdi-plus"
          variant="flat"
          @click="openAddDialog"
          size="large"
        >
          Tambah Pengeluaran
        </v-btn>
      </v-col>
    </v-row>
    <!-- Display room data -->
    <v-data-table-server
      :headers="headers"
      :items="items.value"
      :items-length="items.totalData"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :page="currentPage"
      @update:page="handlePageChange"
      @update:items-per-page="handleItemsPerPageChange"
      class="elevation-1"
    >
      <template v-slot:[`item.index`]="{ index }">
        {{ (currentPage - 1) * itemsPerPage + index + 1 }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <CurrencyConverter :model-value="item.amount"></CurrencyConverter>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          append-icon="mdi-pencil"
          @click="openEditDialog(item)"
          color="primary"
          >Edit</v-btn
        >
        <v-btn
          class="ml-4"
          append-icon="mdi-delete"
          @click="deleteItem(item)"
          color="red"
          >Hapus</v-btn
        >
      </template>
    </v-data-table-server>

    <!-- Add/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-form ref="expenseForm" lazy-validation v-model="expenseForm">
      <v-card>
        <v-card-title>
          <v-row>
            <div class="pa-4">
              <span v-if="isEditMode">Edit Pengeluaran</span>
              <span v-else>Tambah Pengeluaran</span>
            </div>
            <v-spacer />
            <v-btn
              class="pa-4"
              icon
              color="primary"
              @click="dialog = !dialog"
              aria-label="Close"
              variant="plain"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- Form Fields for Add/Edit -->
          <v-text-field
            v-model="editedItem.type"
            label="Tipe"
            variant="outlined"
            :rules="[requiredRule]"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.description"
            label="Deskripsi"
            variant="outlined"
            :rules="[requiredRule]"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.date"
            label="Tanggal"
            type="date"
            variant="outlined"
            :rules="[requiredRule]"
          ></v-text-field>
          <CurrencyInput
            v-model="editedItem.amount"
            :options="{
              currency: 'IDR',
              locale: 'id-ID',
              prefix: 'Rp ',
              precision: 0,
            }"
            :rules="[requiredRule, isPositiveNumber]"
          />
          <v-btn
            color="primary"
            @click="saveItem"
            variant="outlined"
            block
            size="large"
            :disabled="!expenseForm"
          >Simpan</v-btn
          >
        </v-card-text>
      </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
  
  <script>
import dayjs from "dayjs";
import { useStore } from "vuex";
import apiService from "@/services/api";
import CurrencyInput from "@/components/CurrencyInput.vue";
import CurrencyConverter from "@/components/CurrencyConverter.vue";
import Swal from "sweetalert2";

export default {
  components: {
    CurrencyInput,
    CurrencyConverter,
  },

  data() {
    return {
      expenseForm: false,
      items: {
        totalData: 0,
      },
      itemsPerPage: 10,
      currentPage: 1,
      headers: [
        { title: '#', value: 'index', sortable: false },  // Index column
        { title: "Tipe", value: "type" },
        { title: "Deskripsi", value: "description" },
        { title: "Tanggal", value: "date" },
        { title: "Jumlah", value: "amount" },
        { title: "Actions", value: "actions", sortable: false }, // Column for actions
      ],
      search: "",
      dialog: false, // State for add/edit dialog visibility
      isEditMode: false, // State to toggle between add and edit mode
      editedItem: {
        id: null,
        type: "",
        description: "",
        date: dayjs().format("YYYY-MM-DD"),
        amount: "",
      },
    };
  },
  setup() {
    const store = useStore(); // Use store inside setup
    return { store };
  },
  methods: {
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.fetchExpenses();
    },
    handleItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.fetchExpenses();
    },
    async fetchExpenses() {
      try {
        const response = await apiService.get("/expenses/all", {
          params: {
            sort: "id",
            sortBy: "asc",
          },
        });
        if (response.data.responseCode === "00" && response.data.responseData) {
          // Populate the items for the table
          this.items = response.data.responseData;
        }
      } catch (error) {
        console.error("Error fetching residents:", error);
      }
    },
    async searchExpenses() {
      // If the search input is empty, fetch all residents
      if (!this.search) {
        this.fetchExpenses();
        return;
      }

      const filters = [
        {
          name: "",
          operation: "INNER_FILTER_OR",
          subFilter: [
            { name: "type", operation: "CONTAIN", value: this.search },
            { name: "description", operation: "CONTAIN", value: this.search },
          ],
        },
      ];

      try {
        const response = await apiService.post("/expenses/filter", filters, {
          params: { sort: "id", sortBy: "asc" },
        });
        if (response.data.responseCode === "00" && response.data.responseData) {
          this.items = response.data.responseData;
        }
      } catch (error) {
        console.error("Error searching expenses:", error);
      }
    },
    openAddDialog() {
      this.isEditMode = false;
      this.editedItem = {
        id: null,
        type: "",
        description: "",
        date: dayjs().format("YYYY-MM-DD"),
        amount: "",
      };
      this.dialog = true;
    },
    openEditDialog(item) {
      this.isEditMode = true;
      this.editedItem = { ...item };
      this.dialog = true;
    },
    async deleteItem(item) {
      Swal.fire({
        title: "Hapus Pengeluaran",
        text: "Apakah Anda yakin mau menghapus pengeluaran ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await apiService.delete(`/expenses/${item.id}`);

          try {
            if (response.data.responseCode === "00") {
              this.store.dispatch("notification/showNotification", {
                message: `Data berhasil dihapus!`,
                type: "success",
              });
              this.fetchExpenses();
            } else {
              this.store.dispatch("notification/showNotification", {
                message: `Data gagal dihapus! ${response.data.responseMessage}`,
                type: "error",
              });
            }
          } catch (error) {
            this.store.dispatch("notification/showNotification", {
              message: `Data gagal dihapus`,
              error,
              type: "error",
            });
          }
        }
      });
    },
    async saveItem() {
      const requestBody = {
        type: this.editedItem.type,
        description: this.editedItem.description,
        date: this.editedItem.date,
        amount: Number(this.editedItem.amount),
      };

      try {
        let response;
        if (this.isEditMode) {
          response = await apiService.put(
            `/expenses/${this.editedItem.id}`,
            requestBody
          );
        } else {
          response = await apiService.post("/expenses", requestBody);
        }

        console.log(response.data.responseCode, response.data.responseMessage);

        if (response.data.responseCode === "00") {
          this.store.dispatch("notification/showNotification", {
            message: "Data berhasil disimpan!",
            type: "success",
          });
          this.fetchExpenses(); // Refresh the resident list
          this.dialog = false;
        } else {
          this.store.dispatch("notification/showNotification", {
            message: `Data gagal disimpan ${response.data.responseMessage}`,
            type: "error",
          });
          this.dialog = false;
        }
      } catch (error) {
        console.error("Error saving resident:", error);
        this.store.dispatch("notification/showNotification", {
          message: `Data gagal disimpan ${error}`,
          type: "error",
        });
      }
    },
  },
  created() {
    this.fetchExpenses();
  },
};
</script>
