<template>
  <v-container>
    <v-row
    ><v-col cols="auto"> <h1>Laporan</h1> </v-col></v-row
    >
    <v-row class="d-flex align-center mb-4 pt-4" no-gutters>
      <!-- Select Report Type with fixed width -->
      <v-col class="pb-2 pr-2" cols="auto" sm="6" md="4" lg="2" xl="1" xxl="1">
        <v-select
          v-model="selectedReportType"
          :items="reportTypes"
          label="Pilih Jenis Laporan"
          variant="outlined"
          density="compact"
          dense
          hide-details
        ></v-select>
      </v-col>

      <!-- Start Date -->
      <v-col cols="auto" class="pb-2 pr-2" sm="12" md="4" lg="2" xl="1" xxl="1">
        <v-text-field
          v-model="startDate"
          label="Start Date"
          type="date"
          variant="outlined"
          density="compact"
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <!-- End Date -->
      <v-col cols="auto" class="pb-2" sm="12" md="4" lg="2" xl="1" xxl="1">
        <v-text-field
          v-model="endDate"
          label="End Date"
          type="date"
          variant="outlined"
          density="compact"
          hide-details
          dense
        ></v-text-field>
      </v-col>

      <!-- Fetch Report Button -->
      <v-col cols="auto" class="ml-4" xs="12" sm="6" md="4" lg="2" xl="1" xxl="1">
        <v-btn color="primary" @click="fetchReportData">
          Filter Laporan
        </v-btn>
      </v-col>
    </v-row>

    <!-- Data Table -->
    <v-data-table
      :headers="tableHeaders"
      :items="reportData"
      :items-per-page="itemsPerPage"
      v-model:page="currentPage"
      class="elevation-1"
    >
      <template v-slot:[`item.index`]="{ index }">
        {{ (currentPage - 1) * itemsPerPage + index + 1 }}
      </template>
      <template v-slot:[`footer.prepend`]>
        <div class="font-weight-bold text-h6" style="text-align: left; padding: 16px;">
          Total: {{ formatCurrency(totalAmount) }}
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>
  
<script>
import apiService from "@/services/api";
import dayjs from "dayjs";

export default {
  name: "IncomeExpensesPage",
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      selectedReportType: "Income",
      reportTypes: ["Income", "Expenses"],
      startDate: dayjs().subtract(1, 'months').format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      reportData: [],
      tableHeaders: [
        { title: '#', value: 'index', sortable: false },  // Index column
        { title: "Tanggal", value: "date" },
        { title: "Tipe / Term", value: "type" },
        { title: "Deskripsi", value: "description" },
        { title: "Jumlah", value: "amount" },
      ],
      totalAmount: null,
      reportStartDate: null,
      reportEndDate: null,
    };
  },
  computed: {
    totalAmountFormatted() {
      return this.formatCurrency(this.totalAmount);
    },
  },
  created() {
    this.fetchReportData();
  },
  methods: {
    async fetchReportData() {
      if (!this.selectedReportType || !this.startDate || !this.endDate) {
        this.$alert("Please select report type and date range.");
        return;
      }

      const startDateFormatted = dayjs(this.startDate).format("YYYY-MM-DD");
      const endDateFormatted = dayjs(this.endDate).format("YYYY-MM-DD");
      const endpoint =
        this.selectedReportType === "Income"
          ? "/reports/incomes"
          : "/reports/expenses";
      const params = {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      };

      try {
        const response = await apiService.get(endpoint, { params });
        if (response.data.responseCode === "00" && response.data.responseData) {
          const responseData = response.data.responseData;
          const itemsArray = responseData.items;

          if (Array.isArray(itemsArray)) {
            this.reportData = itemsArray.map((item) => ({
              date: this.formatDate(item.date),
              description: item.description,
              amount: this.formatCurrency(parseFloat(item.amount)),
              type: item.type || item.term + ' bulan'
            }));
            console.log("report data : ", this.reportData);

            // Store totalAmount, reportStartDate, reportEndDate
            this.totalAmount = parseFloat(responseData.totalAmount);
            this.reportStartDate = responseData.startDate;
            this.reportEndDate = responseData.endDate;
          } else {
            this.reportData = [];
            console.error("Expected items to be an array");
            this.$alert("No data found.");
          }
        } else {
          this.reportData = [];
          this.$alert(response.data.responseMessage || "No data found.");
        }
      } catch (error) {
        console.error("Error fetching report data:", error);
        this.$alert("An error occurred while fetching the report data.");
      }
    },
    updateStartDate(val) {
      this.startDateFormatted = this.formatDate(val);
    },
    updateEndDate(val) {
      this.endDateFormatted = this.formatDate(val);
    },
    formatDate(date) {
      return dayjs(date).format("DD MMMM YYYY");
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(amount);
    },
    $alert(message) {
      // Simple alert method; you can replace this with your preferred notification system
      alert(message);
    },
  },
};
</script>
  
<style scoped>
</style>
