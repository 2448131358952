// src/mixins/validationMixin.js
export default {
    methods: {
        // Validation for required field
        requiredRule(value) {
            return !!value || 'Kolom ini harus diisi';
        },

        // Validation for number only (integer or float)
        isNumber(value) {
            return !isNaN(value) || 'Harus angka';
        },

        // Validation for positive numbers
        isPositiveNumber(value) {
            return value > 0 || 'Harus angka positif';
        },

        // Validation for integers only
        isInteger(value) {
            return Number.isInteger(parseFloat(value)) || 'Harus angka bulat';
        },

        // Validation for minimum length (for text fields)
        minLength(min) {
            return (value) => (value && value.length >= min) || `Minimum digit ${min}`;
        },

        // Validation for maximum length (for text fields)
        maxLength(max) {
            return (value) => (value && value.length <= max) || `Maksimum digit ${max}`;
        },

        // Validation for email format
        isEmail(value) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(value) || 'Email tidak valid';
        },

        // Validation for URL format
        isURL(value) {
            const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
            return urlPattern.test(value) || 'URL tidak valid';
        },

        // Validation for a selected option (non-empty select fields)
        isRequiredSelect(value) {
            return !!value || 'Silakan pilih opsi';
        },

        // Validation for matching fields (e.g., password confirmation)
        isMatch(value1, value2) {
            return value1 === value2 || 'Data tidak sesuai';
        },

        // Validation for a minimum numeric value (e.g., age)
        minNumber(min) {
            return (value) => parseFloat(value) >= min || `Nominal minimal ${min}`;
        },

        // Validation for a maximum numeric value (e.g., price)
        maxNumber(max) {
            return (value) => parseFloat(value) <= max || `Nominal tidak boleh melebihi ${max}`;
        },
    }
};
