export const namespaced = true;

export const state = {
  headerTitle: "",
  showBackButton: false,
  notifDialog: {
    showDialog: false,
    title: "hejo app",
    type: "",
    message: "",
    lists: null,
  },
  loadingDialog: {
    showDialog: false,
    loadingPercentage: 50,
  },
  appLogo: null,
  params : []
};

export const mutations = {
  SET_HEADER_TITLE(state, stringTitle) {
    state.headerTitle = stringTitle;
  },
  SET_APP_LOGO(state, stringTitle) {
    state.appLogo = stringTitle;
  },
  SET_PARAMS(state, vals) {
    state.params = vals;
  },
  SET_BACK_BUTTON(state, status) {
    state.showBackButton = status;
  },
  SET_NOTIF_DIALOG(state, showNotifDialog) {
    state.notifDialog = showNotifDialog;
  },
  SET_LOADING_DIALOG(state, showLoadingDialog) {
    state.loadingDialog = showLoadingDialog;
  },
};

export const actions = {
  setHeaderTitle({ commit }, headerTitle) {
    commit("SET_HEADER_TITLE", headerTitle);
  },
  setParams({ commit }, params) {
    commit("SET_PARAMS", params);
  },
  setAppLogo({ commit }, params) {
    commit("SET_APP_LOGO", params);
  },
  setBackButton({commit}, status) {
    commit("SET_BACK_BUTTON", status);
  },
  showNotifDialog({ commit }, showNotifDialog) {
    commit("SET_NOTIF_DIALOG", showNotifDialog);
  },
  showLoadingDialog({ commit }, showLoadingDialog) {
    commit("SET_LOADING_DIALOG", showLoadingDialog);
  },
};
