<template>
  <v-card :color="color" variant="flat" class="mx-auto uniform-card text-white">
    <v-card-item>
      <v-row style="min-height: 90px;">
        <v-col cols="8">
          <span class="text-h5 font-weight-bold text-white">
            {{ title }}
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <div
            class="text-h1 font-weight-bold text-white pt-2"
          >
            <v-skeleton-loader
              v-if="isLoading"
              :loading="isLoading"
            ></v-skeleton-loader>
            <span v-if="!isLoading">{{ count }}</span>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <div class="icon">
            <v-icon class="text-white">{{ icon }}</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>
<script>
import apiService from "@/services/api";

export default {
  name: "RoomCard",
  props: {
    filters: Object,
    color: String,
    title: String,
    icon: String,
  },
  data() {
    return {
      isLoading: true,
      count: 0,
    };
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        const response = await apiService.post("/rooms/filter", this.filters, {
          params: {
            page: 1,
            maxRowCount: 1,
          },
        });
        this.isLoading = false;
        this.count = response.data.responseData.totalData;
      } catch (error) {
        console.error("Error fetching rooms data:", error);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
/* Compact card style */
.uniform-card {
  width: auto; /* Fixed width */
  display: flex; /* Flexbox for content alignment */
  flex-direction: column; /* Column layout for content */
  border-radius: 8px; /* Rounded corners */
  color: white;
}

.icon {
  font-size: 70px;
}
</style>
  