<template>
    <v-text-field
        :label=label
        ref="inputRef"
        type="text"
        variant="outlined"
    ></v-text-field>
  </template>
  
  <script>
  import { useCurrencyInput } from "vue-currency-input";
  
  export default {
    name: "CurrencyInput",
    props: {
      modelValue: Number,
      options: Object,
      label: String
    },
    setup(props) {
      const { inputRef } = useCurrencyInput({
        ...props.options,
        currency: 'IDR',
        locale: 'id-ID',
        prefix: 'Rp ', // Set prefix to 'Rp'
        precision: 0, // No decimals
        autoDecimalMode: false,
    });
  
      return { inputRef };
    },
  };
  </script>