<template>
  <v-app>
    <sidebar-component v-model:drawerStatus="drawerStatus" />
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon class="hide-on-large" @click.stop="toggleDrawer()" />
      <v-btn
        v-if="GlobalSettings.showBackButton"
        @click="$router.go(-1)"
        icon
        class="tepat-font"
        color="black"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span v-if="!GlobalSettings.showBackButton" class="pl-10"></span>
      <v-toolbar-title class="ml-0" align="left" style="/* max-width: 300px */">
        <span class="hidden-sm-and-down tepat-font">{{
          GlobalSettings.headerTitle
        }}</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn text class="no-uppercase" @click="logout" appendIcon="mdi-logout">Keluar</v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
  
<script>
import SidebarComponent from "./SidebarComponent.vue";
import { mapState } from "vuex";


export default {
  components: {
    SidebarComponent,
  },
  data() {
    return {
      drawerStatus: true,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    toggleDrawer() {
      this.drawerStatus = !this.drawerStatus;
    },
  },
  computed: {
    ...mapState(["Notification", "GlobalSettings", "User"]),
  }
};
</script>
  