<template>
  <v-container>
    <v-row
    >
      <v-col cols="auto"><h1>Manajemen Penghuni</h1></v-col>
    </v-row
    >
    <v-row justify="space-between">
      <v-col cols="auto">
        <v-responsive class="mx-auto" min-width="300" max-width="400">
          <!-- Search Field -->
          <v-text-field
              v-model="search"
              variant="outlined"
              prepend-inner-icon="mdi-magnify"
              label="Cari Nama/No KTP"
              single-line
              @input="fetchResidents"
              density="compact"
          ></v-text-field>
        </v-responsive>
      </v-col>

      <v-col cols="auto">
        <v-btn
            color="primary"
            append-icon="mdi-plus"
            variant="flat"
            @click="openAddDialog"
            size="large"
        >
          Tambah Penghuni
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table-server
        :headers="headers"
        :items="items.value"
        :items-length="items.totalData"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :page="currentPage"
        @update:page="handlePageChange"
        @update:items-per-page="handleItemsPerPageChange"
        class="elevation-1"
    >
      <!-- Custom slot for the image column -->
      <template v-slot:[`item.index`]="{ index }">
        {{ (currentPage - 1) * itemsPerPage + index + 1 }}
      </template>
      <template v-slot:[`item.idImage`]="{ item }">
        <v-btn
            v-if="item.idImage"
            color="primary"
            @click="viewImage(item.idImage)"
        >
          Lihat KTP
        </v-btn>
        <span v-else>Tidak Ada Gambar</span>
        <!-- Handle case when no image is available -->
      </template>

      <!-- Custom slot for action buttons or other custom columns -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            append-icon="mdi-pencil"
            @click="openEditDialog(item)"
            color="primary"
            v-if="userRoles.includes('owner')"
        >Edit
        </v-btn
        >
      </template>
    </v-data-table-server>

    <!-- Add/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <v-row>
            <div class="pa-4">
              <span v-if="isEditMode">Edit Penghuni</span>
              <span v-else>Tambah Penghuni</span>
            </div>
            <v-spacer/>
            <v-btn
                class="pa-4"
                icon
                color="primary"
                @click="dialog = !dialog"
                aria-label="Close"
                variant="plain"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- Form Fields for Add/Edit -->
          <v-form ref="validNewResidentForm" lazy-validation v-model="validNewResidentForm">

            <v-text-field
                v-model="editedItem.name"
                label="Nama"
                variant="outlined"
                :rules="[requiredRule]"
            ></v-text-field>

            <v-text-field
                v-model="editedItem.email"
                label="Email"
                :rules="[requiredRule, isEmail]"
                required
                variant="outlined"
            ></v-text-field>

            <v-text-field
                v-model="editedItem.phoneNumber"
                label="No HP"
                :rules="[requiredRule, minLength(8), maxLength(14)]"
                required
                variant="outlined"
            ></v-text-field>

            <v-text-field
                v-model="editedItem.idCardNo"
                label="No KTP/Identitas"
                :rules="[requiredRule, minLength(8), maxLength(20)]"
                required
                variant="outlined"
            ></v-text-field>

            <v-text-field
                v-model="editedItem.emergencyContact"
                label="Kontak Darurat"
                :rules="[requiredRule, minLength(8), maxLength(14)]"
                required
                variant="outlined"
            ></v-text-field>

            <!-- Rest of the form fields -->

            <v-file-input
                label="Upload Foto KTP"
                v-model="editedItem.idCard"
                accept="image/*"
                prepend-inner-icon="mdi-camera"
                @change="handleImageUpload"
                variant="outlined"
            ></v-file-input>
            <!-- Image Preview -->
            <v-img
                v-if="imagePreview"
                :src="imagePreview"
                max-width="200"
                max-height="200"
                class="mt-3"
            ></v-img>
            <v-btn
                color="primary"
                @click="saveItem"
                variant="outlined"
                block
                size="large"
                :disabled="!validNewResidentForm"
            >Simpan
            </v-btn
            >
          </v-form>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog for showing the image -->
    <v-dialog v-model="imageDialog" max-width="400">
      <v-card>
        <v-card-title> Foto KTP</v-card-title>
        <v-card-text>
          <v-img :src="selectedImage" max-width="400"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="imageDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/services/api";
import {useStore} from "vuex";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
      validNewResidentForm: false,
      headers: [
        {title: '#', value: 'index', sortable: false},  // Index column
        {title: "Nama", value: "name"},
        {title: "Email", value: "email"},
        {title: "No HP", value: "phoneNumber"},
        {title: "No KTP", value: "idCardNo"}, // Column for KTP number
        {title: "KTP", value: "idImage"}, // Column for KTP image button
        {title: "Kontak Darurat", value: "emergencyContact"},
        {title: "Actions", value: "actions", sortable: false}, // Column for actions
      ],
      valid: false,
      // Validation rules
      items: {
        totalData: 0,
      }, // This will store the list of residents fetched from the API
      search: "",
      imageDialog: false, // State for the image dialog visibility
      selectedImage: "", // Holds the selected image to show in the dialog
      dialog: false, // State for add/edit dialog visibility
      isEditMode: false, // State to toggle between add and edit mode
      editedItem: {
        id: null,
        name: "",
        email: "",
        phoneNumber: "",
        idCardNo: "",
        emergencyContact: "",
        idImage: null, // Add idImage to the edited item
      },
      imagePreview: "",
    };
  },
  setup() {
    const store = useStore(); // Use store inside setup
    return {store};
  },
  methods: {
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.fetchResidents();
    },
    handleItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.fetchResidents();
    },
    async fetchResidents() {
      try {
        const filters = [
          {
            name: "",
            operation: "INNER_FILTER_OR",
            subFilter: [
              {name: "name", operation: "CONTAIN", value: this.search},
              {name: "idCardNo", operation: "CONTAIN", value: this.search},
            ],
          },
        ];
        const response = await apiService.post(
            "/residents/filter",
            this.search ? filters : [],
            {
              params: {
                sort: "id",
                sortBy: "asc",
                page: this.currentPage,
                maxRowCount: this.itemsPerPage,
              },
            }
        );
        if (response.data.responseCode === "00" && response.data.responseData) {
          // Populate the items for the table
          this.items = response.data.responseData;
          this.totalData = response.data.responseData.totalData;
        }
      } catch (error) {
        console.error("Error fetching residents:", error);
      }
    },
    openAddDialog() {
      this.isEditMode = false;
      this.editedItem = {
        id: null,
        name: "",
        email: "",
        phoneNumber: "",
        idCardNo: "",
        emergencyContact: "",
        idImage: "", // Reset the idImage field
      };
      this.imagePreview = "";
      this.dialog = true;
    },
    openEditDialog(item) {
      this.isEditMode = true;
      this.editedItem = {...item};
      this.imagePreview = this.editedItem.idImage
          ? this.editedItem.idImage
          : "";
      this.dialog = true;
    },
    viewImage(idImage) {
      this.selectedImage = idImage; // Set the selected image to display in the dialog
      this.imageDialog = true; // Open the dialog to show the image
    },
    async handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await apiService.post("/upload/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.responseCode === "00") {
            this.store.dispatch("notification/showNotification", {
              message: "KTP berhasil diupload",
              type: "success",
            });
            this.editedItem.idImage = response.data.responseData.path; // Save image URL to edited item
            this.imagePreview = response.data.responseData.path; // Set preview
          } else {
            this.store.dispatch("notification/showNotification", {
              message:
                  response.data.responseMessage ||
                  "An error occurred during login.",
              type: "error",
            });
            console.error(
                "Error uploading image:",
                response.data.responseMessage
            );
          }
        } catch (error) {
          this.store.dispatch("notification/showNotification", {
            message: error.message || "An error occurred during login.",
            type: "error",
          });
          console.error("Error uploading image:", error);
        }
      }
    },
    async saveItem() {
      const requestBody = {
        name: this.editedItem.name,
        email: this.editedItem.email,
        phoneNumber: this.editedItem.phoneNumber,
        idCardNo: this.editedItem.idCardNo,
        emergencyContact: this.editedItem.emergencyContact,
        idImage: this.editedItem.idImage, // Include the uploaded image URL in the request
      };

      try {
        let response;
        if (this.isEditMode) {
          response = await apiService.put(
              `/residents/${this.editedItem.id}`,
              requestBody
          );
        } else {
          response = await apiService.post("/residents", requestBody);
        }

        console.log(response.data.responseCode, response.data.responseMessage);

        if (response.data.responseCode === "00") {
          this.store.dispatch("notification/showNotification", {
            message: "Data berhasil disimpan!",
            type: "success",
          });
          this.fetchResidents(); // Refresh the resident list
          this.dialog = false;
        } else {
          this.store.dispatch("notification/showNotification", {
            message: `Data gagal disimpan ${response.data.responseMessage}`,
            type: "error",
          });
          this.dialog = false;
        }
      } catch (error) {
        console.error("Error saving resident:", error);
        this.store.dispatch("notification/showNotification", {
          message: `Data gagal disimpan ${error}`,
          type: "error",
        });
      }
    },
  },
  created() {
    this.fetchResidents();
  },
  computed: {
    ...mapGetters(["userRoles"]),
  },
};
</script>
