import { createStore } from 'vuex';
import { setAuthToken } from '@/services/api';
import * as GlobalSettings from "./GlobalSettings"

function getInitialState() {
  const token = localStorage.getItem('token');
  const userProfile = localStorage.getItem('userProfile') && localStorage.getItem('userProfile') !== 'undefined' ? JSON.parse(localStorage.getItem('userProfile')) : null;
  let userRoles = [];

  if (userProfile && userProfile.roles) {
    userRoles = userProfile.roles.split(',');
  }

  return {
    isAuthenticated: !!token,
    userRoles: userRoles,
    token: token,
    userProfile: userProfile,
  };
}

export default createStore({
  state: getInitialState(),
  mutations: {
    setAuthentication(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
      state.userRoles = payload.userRoles;
      state.token = payload.token;
      state.userProfile = payload.userProfile;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.userRoles = [];
      state.token = null;
      state.userProfile = null;
      localStorage.removeItem('token');
      localStorage.removeItem('userProfile');
      setAuthToken(null);
    },
  },
  actions: {
    login({ commit }, { token, profile }) {
      try {
        // Store token and profile in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('userProfile', JSON.stringify(profile));

        // Parse roles from profile, which may be a comma-separated string
        const userRoles = profile.roles ? profile.roles.split(',') : [];

        commit('setAuthentication', {
          isAuthenticated: true,
          userRoles: userRoles,
          token: token,
          userProfile: profile,
        });
        setAuthToken(token);
      } catch (error) {
        console.error('Login error:', error);
        // Handle error
      }
    },
    logout({ commit }) {
      commit('logout');
    },
  },
  modules: {
    // Add the notification module
    notification: {
      namespaced: true,
      state: {
        visible: false,
        message: '',
        color: '',
      },
      mutations: {
        show(state, payload) {
          state.visible = true;
          state.message = payload.message;
          state.color = payload.color;
        },
        hide(state) {
          state.visible = false;
          state.message = '';
          state.color = '';
        },
      },
      actions: {
        showNotification({ commit }, { message, type }) {
          let color = '';

          switch (type) {
            case 'success':
              color = 'green';
              break;
            case 'error':
              color = 'red';
              break;
            case 'warning':
              color = 'orange';
              break;
            default:
              color = 'blue';
          }

          commit('show', { message, color });
        },
        hideNotification({ commit }) {
          commit('hide');
        },
      },
    },
    GlobalSettings,
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    userRoles: (state) => state.userRoles,
  },
});
