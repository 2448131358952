<template>
  <div id="app">
    <ShowNotification />
    <MainLayout v-if="isAuthenticated" />
    <router-view v-else></router-view>
  </div>
</template>

<script>
import MainLayout from "./components/MainLayout.vue";
import { mapGetters } from "vuex";
import ShowNotification from "./components/ShowNotification.vue";
import apiService from "@/services/api";
import { mapState } from "vuex";


export default {
  components: {
    MainLayout,
    ShowNotification,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]), ...mapState(["GlobalSettings"])
  },
  async created() {
    apiService.get("/parameters/all", {
          params: {
            sort: "id",
            sortBy: "asc",
          },
        }).then(response => {
          const valueMap = [];
          response.data.responseData.value.forEach(val => {
            valueMap[val.key] = val;
          });
        this.$store.dispatch(
            "GlobalSettings/setHeaderTitle",
            valueMap['APP_TITLE'].value
        )
        this.$store.dispatch(
            "GlobalSettings/setParams",
            valueMap
        )
        this.$store.dispatch(
            "GlobalSettings/setAppLogo",
            valueMap['APP_LOGO'].value
        )
        })
       
  }
};
</script>

<style lang="scss" scoped>
::v-deep .popup-title {
    font-size: 1.25rem;
}
</style>