<template>
    <div style="font-weight: normal;">{{  formattedPrice }} </div>
  </template>
  
  <script>  
  export default {
    name: "CurrencyConverter",
    props: {
      modelValue: Number,
    },
    computed: {
        formattedPrice() {
        // Use Intl.NumberFormat to format the number as currency
            return new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0, // No decimals
            }).format(this.modelValue);
        },
    },
  };
  </script>