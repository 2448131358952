import axios from 'axios';
import store from '@/store';
import router from '@/router';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
});

export function setAuthToken(token) {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
}


// Request interceptor to add token to headers if available
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Adjust storage as needed
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


api.interceptors.response.use(
  (response) => {
    if(response.data.responseCode === "401") {
      store.dispatch('logout');
      router.push('/login');
    }
    return response;
  },
  (error) => {
    // Handle unauthorized access
    if (error.response && error.response.status === 401) {
      // Possibly dispatch logout action
      store.dispatch('logout');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

// Export HTTP methods
export default {
  get(endpoint, params) {
    return api.get(endpoint,  params );
  },
  post(endpoint, data, params) {
    return api.post(endpoint, data, params);
  },
  put(endpoint, data) {
    return api.put(endpoint, data);
  },
  delete(endpoint) {
    return api.delete(endpoint);
  },
};
