<template>
  <v-container fluid class="login-container" style="height: 100vh;">
  <v-row align="center" justify="center" class="fill-height">
    <v-col cols="12" sm="8" md="4">
      <v-row justify="center" class="mb-4">
        <v-img
          :src="require('@/assets/logo.jpeg')"
          height="200px"
          width="auto"
          max-height="200px"
        ></v-img>
      </v-row>

      <v-card>
        <v-card-title class="justify-center">
          <span class="headline">Masuk</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login">
            <v-text-field
              label="Username"
              v-model="username"
              required
            ></v-text-field>
            <v-text-field
              label="Password"
              type="password"
              v-model="password"
              required
            ></v-text-field>
            <v-btn type="submit" color="primary" block> Masuk </v-btn>
          </v-form>
          <ShowNotification />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>

</template>

<script>
import apiService from "@/services/api";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import logo from "@/assets/logo.png"; // Adjust the path to your logo image
import ShowNotification from "@/components/ShowNotification.vue";

export default {
  name: "LoginView",
  components: {
    ShowNotification,
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const store = useStore();
    const router = useRouter();

    const login = async () => {
      if (username.value && password.value) {
        try {
          const response = await apiService.post("/auth/login", {
            username: username.value,
            password: password.value,
          });

          // Validate the response code
          if (response.data.responseCode === "00") {
            // Success case
            const token = response.data.responseData.access_token;
            const profile = response.data.responseData.profile;

            // Dispatch login action with the received token and profile
            store.dispatch("login", { token, profile });

            // Display success notification
            store.dispatch("notification/showNotification", {
              message: "Login successful!",
              type: "success",
            });

            router.push("/");
          } else {
            // Handle error: responseCode is not '00'
            const errorMessage =
              response.data.responseMessage ||
              "Login failed. Please check your credentials.";
            throw new Error(errorMessage);
          }
        } catch (error) {
          console.error(error);

          // Display error notification with the error message
          store.dispatch("notification/showNotification", {
            message: error.message || "An error occurred during login.",
            type: "error",
          });
        }
      } else {
        // Display warning notification
        store.dispatch("notification/showNotification", {
          message: "Tolong isi semua field!",
          type: "warning",
        });
      }
    };

    return {
      username,
      password,
      login,
      logo,
    };
  },
};
</script>

<style scoped>
.login-container {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.v-card {
  padding: 20px;
}

.headline {
  font-weight: bold;
}

.v-btn {
  margin-top: 20px;
}
</style>
