<template>
  <v-container>
    <v-row
      ><v-col cols="auto"> <h1>Manajemen Kamar</h1> </v-col></v-row
    >
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn
          color="primary"
          prepend-icon="mdi-filter-variant"
          variant="flat"
          outlined
          class="no-uppercase"
          @click="filterDialog = true"
          v-model="filter"
          size="large"
        >
          Filter
        </v-btn>
      </v-col>
    </v-row>
    <!-- Display room data -->
    <div v-for="(floor, index) in floors" :key="index" class="mb-4">
      <!-- Display floor title -->
      <div class="text-h4 pb-2">Lantai: {{ floor.number }}</div>
      <!-- Grid layout for rooms -->
      <v-row>
        <v-col
          v-for="(room, roomIndex) in floor.rooms"
          :key="roomIndex"
          cols="12"
          xs="12"
          sm="12"
          md="6"
          lg="4"
          xl="3"
          xxl="2"
        >
          <v-card outlined class="room-card">
            <v-card-title :class="getRoomStatusClass(room)" class="text-white">
              <v-row>
                <v-col cols="6" class="text-h2 font-weight-bold">{{
                  room.roomNumber
                }}</v-col>
                <v-col cols="6" class="text-right">
                  <div>{{ room.roomType }}</div>
                  <div>
                    <CurrencyConverter
                      :model-value="room.pricePerMonth"
                      class="text-h6"
                    ></CurrencyConverter>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-center text-h5 font-weight-bold">{{
                  getRoomStatus(room)
                }}</v-col>
              </v-row>
            </v-card-title>
            <v-divider></v-divider>

            <!-- Tenant details or empty room -->
            <v-card-text v-if="room.roomResident">
              <p class="text-h5 font-weight-bold">
                {{ room.roomResident.resident.name }}
              </p>
              <p class="text-h6">
                {{ room.roomResident.resident.phoneNumber }}
              </p>
              <p>Pembayaran Terakhir</p>
              <p class="text-h6">
                <strong>{{
                  formatDate(room.roomResident.lastPaymentDate)
                }}</strong>
              </p>
              <p></p>
              <p>Jatuh Tempo</p>
              <p class="text-h6">
                <strong>{{ formatDate(room.roomResident.dueDate) }}</strong>
              </p>
              <p>Pembersihan Kamar Terakhir</p>
              <p class="text-h6">
                <strong>{{
                  formatDate(room.roomResident.lastCleaningDate)
                }}</strong>
              </p>
              <!-- <p class="payment-info">
                  <span>Pembersihan Kamar Selanjutnya</span>
                  <strong>{{
                    formatDate(room.roomResident.nextCleaningDate)
                  }}</strong>
                </p> -->
            </v-card-text>
            <v-card-text v-else>
              <p class="text-h5 font-weight-bold">Kamar Kosong</p>
              <p>Available</p>
            </v-card-text>
            <v-divider></v-divider>

            <!-- Buttons: Detail/Edit, Input Payment, Schedule Cleaning -->
            <v-card-actions class="flex-column">
              <v-row justify="space-between" class="w-100">
                <!-- EDIT/DETAIL Button -->
                <v-col :cols="room.roomResident ? 4 : 5">
                  <v-btn
                    color="primary"
                    @click="goToDetailEdit(room.id)"
                    block
                    variant="outlined"
                  >
                    <v-icon large>mdi-pencil</v-icon>
                    <span>Detail</span>
                  </v-btn>
                </v-col>

                <!-- PAYMENT Button -->
                <v-col cols="4" v-if="room.roomResident">
                  <v-btn
                    color="success"
                    @click="openPaymentDialog(room)"
                    class="d-flex flex-column align-center"
                    block
                    variant="outlined"
                  >
                    <v-icon large>mdi-currency-usd</v-icon>
                    <span>Payment</span>
                  </v-btn>
                </v-col>

                <!-- CLEANING Button -->
                <v-col cols="4" v-if="room.roomResident">
                  <v-btn
                    color="warning"
                    @click="openCleaningDialog(room)"
                    class="d-flex flex-column align-center"
                    block
                    variant="outlined"
                  >
                    <v-icon large>mdi-broom</v-icon>
                    <span>Cleaning</span>
                  </v-btn>
                </v-col>

                <v-col cols="7" v-if="!room.roomResident">
                  <v-btn
                    color="success"
                    @click="openAddResidentDialog(room)"
                    class="d-flex flex-column align-center"
                    block
                    variant="outlined"
                  >
                    <v-icon large>mdi-clipboard-account</v-icon>
                    <span>Tambah Penghuni</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Dialog to show room details -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title>
          Detail Kamar {{ selectedRoom.roomNumber }}
        </v-card-title>
        <v-card-text>
          <p>
            <strong>Status :</strong>
            {{
              selectedRoom.status.toLowerCase() == "vacant"
                ? "Kosong"
                : "Terisi"
            }}
          </p>
          <p>
            <strong>Nama :</strong>
            {{ selectedRoom.roomResident?.resident?.name || "-" }}
          </p>
          <p>
            <strong>Tanggal Jatuh Tempo :</strong>
            {{ selectedRoom.roomResident?.resident?.dueDate || "-" }}
          </p>
          <p>
            <strong>Terakhir Bayar :</strong>
            {{ selectedRoom.roomResident?.resident?.lastPaymentDate || "-" }}
          </p>
          <CurrencyConverter
            :model-value="selectedRoom.pricePerMonth"
          ></CurrencyConverter>
          <!-- Add more details as needed -->
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="4">
              <v-btn
                block
                color="primary"
                @click="goToDetailEdit(selectedRoom.id)"
                class="d-flex flex-column align-center"
              >
                <v-icon large class="mb-2">mdi-pencil</v-icon>
                Detail/Edit
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                block
                color="primary"
                class="d-flex flex-column align-center"
              >
                <v-icon large class="mb-2">mdi-credit-card</v-icon>
                Pembayaran
              </v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                block
                color="primary"
                @click="dialog = false"
                class="d-flex flex-column align-center"
              >
                <v-icon large class="mb-2">mdi-close</v-icon>
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentDialog" max-width="400">
      <v-form ref="paymentForm" lazy-validation v-model="validPaymentForm">
        <v-card>
          <v-card-title>
            <v-row>
              <div class="pa-2">
                Pembayaran Kamar {{ selectedRoom.roomNumber }}
              </div>
              <v-spacer />
              <v-btn
                icon
                color="primary"
                @click="paymentDialog = !paymentDialog"
                aria-label="Close"
                variant="plain"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn></v-row
            >
          </v-card-title>
          <v-card-text>
            <p><strong>Jumlah Bulan </strong></p>
            <v-select
              v-model="paymentTerm"
              :items="termMonths"
              clearable
              variant="outlined"
              :rules="[isRequiredSelect]"
            ></v-select>
            <p><strong>Jumlah Pembayaran</strong></p>
            <v-text-field
              :value="formattedAmount"
              readonly
              variant="outlined"
            ></v-text-field>
            <p><strong>Tanggal Pembayaran </strong></p>
            <v-text-field
              v-model="placeholderPaymentDate"
              variant="outlined"
              type="date"
              :rules="[requiredRule]"
            ></v-text-field>
            <v-btn
              variant="outlined"
              block
              color="primary"
              size="large"
              @click="inputPayment"
              :disabled="!validPaymentForm"
              >Simpan</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="cleaningDialog" max-width="400">
      <v-form ref="cleaningForm" lazy-validation v-model="validCleaningForm">
        <v-card>
          <!-- Title -->
          <v-card-title class="headline">
            <v-row>
              <div class="pa-2">
                Pembersihan Kamar {{ selectedRoom.roomNumber }}
              </div>
              <v-spacer />
              <v-btn
                icon
                color="primary"
                @click="cleaningDialog = !cleaningDialog"
                aria-label="Close"
                variant="plain"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>

          <!-- Content -->
          <v-card-text>
            <!-- Last Cleaning Date -->
            <p><strong>Pembersihan Sebelumnya</strong></p>
            <v-text-field
              :value="
                selectedRoom.roomResident.lastCleaningDate || 'Data Kosong'
              "
              variant="outlined"
              readonly
            ></v-text-field>
            <!-- Next Cleaning Date Input -->
            <p><strong>Pembersihan Kamar Selanjutnya</strong></p>
            <v-text-field
              v-model="nextCleaningDate"
              type="date"
              variant="outlined"
              :rules="[requiredRule]"
            ></v-text-field>
            <v-btn
              color="primary"
              @click="
                nextCleaningInput(selectedRoom.roomResident.lastCleaningDate)
              "
              variant="outlined"
              block
              size="large"
              :disabled="!validCleaningForm"
              >Simpan</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="residentDialog" max-width="500">
      <v-card>
        <!-- Title -->
        <v-card-title class="headline">
          <v-row>
            <div class="pa-2">
              Tambah Penghuni untuk Kamar {{ selectedRoom.roomNumber }}
            </div>
            <v-spacer />
            <v-btn
              icon
              color="primary"
              @click="residentDialog = !residentDialog"
              aria-label="Close"
              variant="plain"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>

        <!-- Content -->
        <v-card-text>
          <p><strong>Jenis Penghuni</strong></p>
          <v-select
            v-model="residentDetail.type"
            :items="['Lama', 'Baru']"
            variant="outlined"
          ></v-select>
          <template v-if="residentDetail.type === 'Lama'">
            <v-form
              ref="existingResidentForm"
              lazy-validation
              v-model="validExistingResidentForm"
            >
              <p color="gray">Nama</p>

              <v-autocomplete
                @update:search="fetchResidents"
                v-model="residentDetail.residentId"
                v-model:search="searchQuery"
                :item-title="(item) => `${item.title} - ${item.idCardNo}`"
                :item-value="(item) => `${item.value}`"
                :items="residentNames"
                :rules="[isRequiredSelect]"
                variant="outlined"
              >
              </v-autocomplete>
              <p color="gray">Tanggal Masuk</p>

              <v-text-field
                v-model="residentDetail.joinDate"
                type="date"
                variant="outlined"
                :rules="[requiredRule]"
              ></v-text-field>
              <p color="gray">Deposit Kamar</p>
<!--              <CurrencyInput
                v-model="residentDetail.deposit"
                readonly="true"
                :options="{
                  currency: 'IDR',
                  locale: 'id-ID',
                  prefix: 'Rp ',
                  precision: 0,
                }"
                :rules="[requiredRule, isPositiveNumber]"
              />-->
              <p class="pb-3">{{formatCurrency(residentDetail.deposit)}}</p>
              <v-btn
                color="primary"
                @click="assignNewOrExisting"
                variant="outlined"
                :disabled="!validExistingResidentForm"
                block
                size="large"
                >Simpan</v-btn
              >
            </v-form>
          </template>
          <template v-else>
            <v-form
              ref="validNewResidentForm"
              lazy-validation
              v-model="validNewResidentForm"
            >
              <v-text-field
                v-model="residentDetail.name"
                label="Nama"
                variant="outlined"
                :rules="[requiredRule]"
              ></v-text-field>
              <v-text-field
                v-model="residentDetail.email"
                label="Email"
                required
                variant="outlined"
                :rules="[requiredRule, isEmail]"
              ></v-text-field>

              <v-text-field
                v-model="residentDetail.phoneNumber"
                label="No HP"
                :rules="[requiredRule, minLength(8), maxLength(14)]"
                required
                variant="outlined"
              ></v-text-field>

              <v-text-field
                v-model="residentDetail.idCardNo"
                label="No KTP/Identitas"
                :rules="[requiredRule, minLength(8), maxLength(20)]"
                required
                variant="outlined"
              ></v-text-field>

              <v-text-field
                v-model="residentDetail.emergencyContact"
                label="Kontak Darurat"
                :rules="[requiredRule, minLength(8), maxLength(14)]"
                required
                variant="outlined"
              ></v-text-field>

              <p color="gray">Deposit Kamar</p>
<!--              <CurrencyInput
                v-model="residentDetail.deposit"
                readonly="true" 
                :options="{
                  currency: 'IDR',
                  locale: 'id-ID',
                  prefix: 'Rp ',
                  precision: 0,
                }"
                :rules="[requiredRule, isPositiveNumber]"
              />-->
              <p class="pb-3">{{formatCurrency(residentDetail.deposit)}}</p>

              <v-file-input
                label="Upload Foto KTP"
                v-model="residentDetail.idCard"
                accept="image/*"
                prepend-inner-icon="mdi-camera"
                @change="handleImageUpload"
                variant="outlined"
              ></v-file-input>
              <!-- Image Preview -->
              <v-img
                v-if="imagePreview"
                :src="imagePreview"
                max-width="200"
                max-height="200"
                class="mt-3"
              ></v-img>
              <p color="gray">Tanggal Masuk</p>

              <v-text-field
                v-model="residentDetail.joinDate"
                type="date"
                variant="outlined"
                :rules="[requiredRule]"
              ></v-text-field>
              <v-btn
                color="primary"
                @click="assignNewOrExisting"
                variant="outlined"
                :disabled="!validNewResidentForm"
                block
                size="large"
                >Simpan</v-btn
              >
            </v-form>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="filterDialog" max-width="400">
      <v-card>
        <v-card-title>
          <v-row>
            <div class="pa-2">Filter</div>
            <v-spacer />
            <v-btn
              icon
              color="primary"
              @click="resetFilter"
              aria-label="Reset"
              variant="plain"
            >
              <v-icon>mdi-refresh</v-icon>
              <!-- Use mdi-refresh for reset, or change to a different icon -->
            </v-btn>

            <!-- Close button -->
            <v-btn
              icon
              color="primary"
              @click="filterDialog = !filterDialog"
              aria-label="Close"
              variant="plain"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p><strong>Lantai</strong></p>
          <v-select
            v-model="selectedFloor"
            :items="floors.map((floor) => floor.number)"
            clearable
            variant="outlined"
          ></v-select>

          <p><strong>Status Kamar</strong></p>
          <v-select
            v-model="selectedStatus"
            :items="['Terisi', 'Kosong']"
            clearable
            variant="outlined"
          ></v-select>

          <p><strong>Status Pembayaran</strong></p>
          <v-select
            v-model="selectedPaymentStatus"
            :items="[
              'Lunas',
              'Hampir Jatuh Tempo',
              'Jatuh Tempo',
              'Telat Bayar',
            ]"
            clearable
            variant="outlined"
          ></v-select>
          <v-btn
            block
            color="primary"
            @click="fetchRooms"
            variant="outlined"
            size="large"
            >Apply</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addRoomDialog" max-width="400">
      <v-card>
        <v-card-title> Tambah Kamar </v-card-title>
        <v-card-text>
          <!-- Room Number Input -->
          <v-text-field label="No Kamar" type="number" outlined></v-text-field>

          <!-- Floor Input -->
          <v-text-field label="Lantai" type="number" outlined></v-text-field>

          <!-- Price Input -->
          <CurrencyInput
            v-model="value"
            :options="{
              currency: 'IDR',
              locale: 'id-ID',
              prefix: 'Rp ',
              precision: 0,
            }"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary">Apply</v-btn>
          <v-btn color="secondary">Reset</v-btn>
          <v-btn color="secondary" @click="addRoomDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
  <script>
import apiService from "@/services/api";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import CurrencyConverter from "@/components/CurrencyConverter.vue";
import Swal from "sweetalert2";
import { useStore } from "vuex";
import CurrencyInput from "@/components/CurrencyInput.vue";

dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
export default {
  components: {
    CurrencyConverter,
    CurrencyInput
  },
  setup() {
    const store = useStore(); // Use store inside setup
    return { store };
  },
  data() {
    return {
      validPaymentForm: false,
      validCleaningForm: false,
      validNewResidentForm: false,
      validExistingResidentForm: false,
      amount: 0,
      paymentDatePicker: false,
      maxDate: new Date().toISOString().substr(0, 10),
      value: null,
      dialog: false,
      filterDialog: false,
      addRoomDialog: false,
      selectedRoom: {},
      filter: "",
      selectedFloor: null,
      selectedStatus: null,
      selectedPaymentStatus: null,
      floors: [],
      paymentDialog: false,
      cleaningDialog: false,
      searchQuery: "",
      residentDialog: false,
      residentNames: [],
      imagePreview: null,
      residentDetail: {
        type: "Lama",
        residentId: null,
        joinDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        name: null,
        email: null,
        phoneNumber: null,
        idCardNo: null,
        emergencyContact: null,
        idCard: null,
        valid: false,
        deposit: 1000000,
        // Validation rules
      },
      placeholderPaymentDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      paymentPrice: null,
      paymentTerm: null,
      nextCleaningDate: dayjs().format("YYYY-MM-DD"),
      termMonths: Array.from({ length: 36 }, (_, i) => i + 1),
    };
  },
  methods: {
    getRoomStatusClass(room) {
      const today = dayjs();
      if (!room.roomResident) return "empty-room-header"; // Grey for empty room

      const dueDate = dayjs(room.roomResident.dueDate);

      if (dueDate.isBefore(today, "day")) {
        return "late-room-header"; // Red for "Late"
      } else if (dueDate.isSame(today, "day")) {
        return "due-today-room-header"; // Orange for "Due Today"
      } else if (dueDate.diff(today, "day") <= 3) {
        return "near-due-room-header"; // Yellow for "Near Due Date"
      } else if (!dueDate.isValid()) {
        return "no-payment-room-header";
      }
      return "lunas-room-header"; // Default to green
    },
    getRoomStatus(room) {
      const today = dayjs();
      if (!room.roomResident) return "Kosong"; // Empty room status

      const dueDate = dayjs(room.roomResident.dueDate);
      console.log(dueDate);
      if (dueDate.isBefore(today, "day")) {
        return "Terlambat"; // Past due date
      } else if (dueDate.isSame(today, "day")) {
        return "Jatuh Tempo"; // Due today
      } else if (dueDate.diff(today, "day") <= 3) {
        return "Hampir Jatuh Tempo"; // Near due date (within 3 days)
      } else if (!dueDate.isValid()) {
        return "Masuk Belum Payment";
      }
      return "Lunas"; // Default to Paid status
    },
    formatDate(date) {
      if (date === "undefined" || date === null) {
        return "-";
      }
      return dayjs(date).format("D MMMM YYYY");
    },
    buildFilters() {
      const filters = [];

      // Filter by Floor
      if (this.selectedFloor !== null) {
        filters.push({
          name: "floor",
          operation: "EQUAL",
          value: this.selectedFloor,
        });
      }

      // Filter by Status (Terisi / Kosong)
      if (this.selectedStatus !== null) {
        const statusMapping = {
          Terisi: "Occupied",
          Kosong: "Vacant",
        };
        filters.push({
          name: "status",
          operation: "EQUAL",
          value: statusMapping[this.selectedStatus],
        });
      }
      // Filter by Payment Status (Paid, Due Soon, Overdue)
      if (this.selectedPaymentStatus !== null) {
        switch (this.selectedPaymentStatus) {
          case "Lunas": {
            const today = dayjs();
            filters.push({
              name: "roomResident.dueDate",
              operation: "GREATER_THAN_EQUAL",
              value: today.format("YYYY-MM-DD"),
            });
            filters.push({
              name: "roomResident.lastPaymentDate",
              operation: "LESS_THAN_EQUAL",
              value: today.format("YYYY-MM-DD"),
            });
            break;
          }

          case "Hampir Jatuh Tempo": {
            filters.push({
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            });
            filters.push({
              name: "roomResident.dueDate",
              operation: "LESS_THAN",
              value: dayjs().add(3, "days").format("YYYY-MM-DD"),
            });
            filters.push({
              name: "roomResident.dueDate",
              operation: "GREATER_THAN",
              value: dayjs().format("YYYY-MM-DD"),
            });
            break;
          }
          case "Jatuh Tempo": {
            filters.push({
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            });
            filters.push({
              name: "roomResident.dueDate",
              operation: "EQUAL",
              value: dayjs().format("YYYY-MM-DD"),
            });
            break;
          }

          case "Telat Bayar": {
            filters.push({
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            });
            filters.push({
              name: "roomResident.dueDate",
              operation: "LESS_THAN",
              value: dayjs().format("YYYY-MM-DD"),
            });
            break;
          }

          default:
            break;
        }
      }
      return filters;
    },
    async inputPayment() {
      const bodyRequest = {
        roomResidentId: Number(this.selectedRoom.roomResident.id),
        paymentDate: this.placeholderPaymentDate,
        amount:
          Number(this.selectedRoom.pricePerMonth) * Number(this.paymentTerm),
        term: Number(this.paymentTerm),
      };
      try {
        const response = await apiService.post(
          "/payments/create/",
          bodyRequest
        );
        if (response.data !== null) {
          Swal.fire({
            title: "Sukses",
            text: "Pembayaran Sukses!",
            icon: "success",
            confirmButtonText: "Tutup",
          });
          this.paymentDialog = false;
          this.fetchRooms();
        } else {
          alert("Payment input success");
          this.paymentDialog = false;
          console.log("input payment", response.data.responseMessage);
          this.fetchRooms();
          alert(response.data, response.message);
        }
      } catch (error) {
        alert(error);
      }
    },
    isNextCleaningDateValid(nextCleaningDateStr, lastCleaningDateStr) {
      if (!lastCleaningDateStr) {
        // If there's no last cleaning date, any next cleaning date is valid
        return true;
      }

      const nextCleaningDate = dayjs(nextCleaningDateStr, 'DD-MM-YYYY');
      const lastCleaningDate = dayjs(lastCleaningDateStr, 'DD-MM-YYYY');

      // If the date parsing fails, log an error and return false
      if (!nextCleaningDate.isValid() || !lastCleaningDate.isValid()) {
        console.error('Invalid date format. Please use DD-MM-YYYY.');
        return false;
      }

      // Ensure the next cleaning date is **after** the last cleaning date
      if (nextCleaningDate.isSameOrBefore(lastCleaningDate)) {
        return false; // Invalid if the date is the same or before
      }

      // Optionally, you can add a check to ensure it's not too soon after the last cleaning date (e.g., within the same week):
      const nextWeekStart = lastCleaningDate.add(1, 'week').startOf('isoWeek');
      return nextCleaningDate.isSameOrAfter(nextWeekStart);
    },
    async nextCleaningInput(lastCleaningDate) {
      const bodyRequest = {
        roomNumber: Number(this.selectedRoom.id),
        cleaningDate: this.nextCleaningDate,
      };
      if (!this.isNextCleaningDateValid(this.nextCleaningDate, lastCleaningDate)) {
        this.cleaningDialog = false;
        Swal.fire({
          title: "Error",
          text: "Tanggal pembersihan harus lebih dari tanggal sebelumnya",
          icon: "error",
          confirmButtonText: "Tutup",
        });
      } else {
        try {
          const response = await apiService.post(
            "/occupancies/set-cleaning",
            bodyRequest
          );
          if (response.status === 201) {
            Swal.fire({
              title: "Success!",
              text: "Penjadwalan pembersihan kamar sukses",
              icon: "success",
              confirmButtonText: "Tutup",
            });
            this.cleaningDialog = false;
            this.fetchRooms();
          } else {
            alert(response.data.responseMessage);
          }
        } catch (error) {
          Swal.fire({
            title: "Error sistem",
            text: error,
            icon: "error",
            confirmButtonText: "Cool",
          });
        }
      }
    },
    async fetchResidents() {
      try {
        const response = await apiService.post(
          "/residents/filter",
          this.searchQuery
            ? [
                {
                  name: "",
                  operation: "INNER_FILTER_OR",
                  subFilter: [
                    {
                      name: "name",
                      operation: "CONTAIN",
                      value: this.searchQuery,
                    },
                    {
                      name: "idCardNo",
                      operation: "CONTAIN",
                      value: this.searchQuery,
                    },
                  ],
                },
              ]
            : [],
          {
            params: {
              sort: "name",
              sortBy: "asc",
            },
          }
        );

        if (response.data.responseCode === "00") {
          this.residentNames = response.data.responseData.value.map(
            (resident) => ({
              title: resident.name,
              value: resident.id,
              idCardNo: resident.idCardNo,
            })
          );
          console.log("Selected resident ID:", this.selectedResident);
          console.log("Resident names:", this.residentNames);
        } else {
          console.error("Error fetching residents");
        }
      } catch (error) {
        console.error("API error:", error);
      }
    },
    async fetchRooms() {
      const filters = this.buildFilters(); // Build the filters dynamically

      try {
        const response = await apiService.post("/rooms/filter", filters, {
          params: {
            sort: "floor,roomNumber",
            sortBy: "asc,asc",
            page: 1,
            maxRowCount: 10000,
          },
        });
        console.log("API Response:", response.data); // Log the entire response

        if (
          response.data.responseCode === "00" &&
          response.data.responseData &&
          response.data.responseData.value
        ) {
          const rooms = response.data.responseData.value;

          // Group rooms by floor
          const groupedRooms = rooms.reduce((acc, room) => {
            const floorNumber = room.floor;
            if (!acc[floorNumber]) {
              acc[floorNumber] = {
                number: floorNumber,
                rooms: [],
              };
            }
            acc[floorNumber].rooms.push(room);
            return acc;
          }, {});

          // Convert grouped object to array
          this.floors = Object.values(groupedRooms);
        } else {
          const errorMessage =
            response.data.responseMessage || "Error fetching rooms data";
          throw new Error(errorMessage);
        }
      } catch (error) {
        console.error("Error fetching rooms data:", error);
      }
      this.filterDialog = false;
    },
    openPaymentDialog(room) {
      this.selectedRoom = room;
      this.paymentDialog = true;
    },
    openCleaningDialog(room) {
      this.selectedRoom = room;
      this.cleaningDialog = true;
    },
    openAddResidentDialog(room) {
      this.selectedRoom = room;
      this.residentDialog = true;
    },
    goToDetailEdit(roomId) {
      this.$router.push({ name: "DetailEditKamar", params: { roomId } });
    },
    async handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        try {
          const response = await apiService.post("/upload/image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          if (response.data.responseCode === "00") {
            this.store.dispatch("notification/showNotification", {
              message: "KTP berhasil diupload",
              type: "success",
            });
            this.residentDetail.idImage = response.data.responseData.path; // Save image URL to edited item
            this.imagePreview = response.data.responseData.path; // Set preview
          } else {
            this.store.dispatch("notification/showNotification", {
              message:
                response.data.responseMessage ||
                "An error occurred during login.",
              type: "error",
            });
            console.error(
              "Error uploading image:",
              response.data.responseMessage
            );
          }
        } catch (error) {
          this.store.dispatch("notification/showNotification", {
            message: error.message || "An error occurred during login.",
            type: "error",
          });
          console.error("Error uploading image:", error);
        }
      }
    },
    resetFilter() {
      // Reset selected filters
      this.selectedFloor = null;
      this.selectedStatus = null;
      this.selectedPaymentStatus = null;
    },
    calculateAmount() {
      // Calculate the amount based on payment term and selected room price
      this.amount =
        Number(this.paymentTerm) * Number(this.selectedRoom.pricePerMonth);
    },
    async assignNewOrExisting() {
      if (this.residentDetail.type === "Baru") {
        //create new resident
        const requestBody = {
          name: this.residentDetail.name,
          email: this.residentDetail.email,
          phoneNumber: this.residentDetail.phoneNumber,
          idCardNo: this.residentDetail.idCardNo,
          emergencyContact: this.residentDetail.emergencyContact,
          idImage: this.residentDetail.idImage,
        };

        try {
          let response = await apiService.post("/residents", requestBody);

          console.log(
            response.data.responseCode,
            response.data.responseMessage
          );

          if (response.data.responseCode === "00") {
            this.residentDetail.residentId = response.data.responseData.id;
            this.store.dispatch("notification/showNotification", {
              message: "Penghuni berhasil dibuat!",
              type: "success",
            });
          } else {
            this.store.dispatch("notification/showNotification", {
              message: `Data gagal disimpan ${response.data.responseMessage}`,
              type: "error",
            });
          }
        } catch (error) {
          console.error("Error saving resident:", error);
          this.store.dispatch("notification/showNotification", {
            message: `Data gagal disimpan ${error}`,
            type: "error",
          });
        }
      }
      if (!this.residentDetail.residentId) {
        alert("Please select a resident to assign");
        return;
      }

      const requestBody = {
        roomId: this.selectedRoom.id,
        residentId: this.residentDetail.residentId,
        joinDate: this.placeholderJoinDate || dayjs().format("YYYY-MM-DD"), // Set the current date for joinDate
        notes: "",
        deposit: this.residentDetail.deposit
      };

      console.log("residentId", this.residentDetail.residentId)
      try {
        const response = await apiService.post(
          "/occupancies/assign",
          requestBody
        );

        if (response.data.occupancyStatus === "Active") {
          this.store.dispatch("notification/showNotification", {
            message: "Berhasil menambahkan penghuni",
            type: "success",
          });
          Swal.fire({
            title: "Sukses",
            text: "Berhasil menambahkan penghuni!",
            icon: "success",
            confirmButtonText: "Tutup",
          });
          this.fetchRooms();
          this.residentDialog = false;
          this.residentDetail = {};
          // console.log("tenant name", this.tenant_name, "extract text", this.residentNames.find(resident => resident.value === this.selectedResident).text);
        } else {
          console.error(
            "Error assigning resident:",
            response.data.responseMessage
          );
          this.store.dispatch("notification/showNotification", {
            message: `Error menambahkan penghuni : ${response.data.responseMessage}`,
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error assigning resident:", error);
        this.store.dispatch("notification/showNotification", {
          message: `Error menambahkan penghuni : ${error}`,
          type: "error",
        });
      }
    },
    isDateWithinSameWeek(dateToCheck, lastCleaning) {
      const lastCleaningWeekStart = dayjs(lastCleaning).startOf('isoWeek');
      const startOfWeek = lastCleaningWeekStart.add(1, 'week');
      const endOfWeek = startOfWeek.endOf('isoWeek');
      
      console.log('Date to check:', dateToCheck);
      console.log('Last cleaning date:', lastCleaning);
      console.log('Next week start:', startOfWeek.format('YYYY-MM-DD'));
      console.log('Next week end:', endOfWeek.format('YYYY-MM-DD'));
      // const startOfWeek = dayjs(lastCleaning).startOf("week");
      // const endOfWeek = dayjs(lastCleaning).endOf("week");
      // console.log(dateToCheck, lastCleaning);

      // Check if the dateToCheck is within the same week as lastCleaning
      return dayjs(dateToCheck).isBetween(startOfWeek, endOfWeek, null, "[]");
    },
    formatCurrency(amount) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(amount);
    },
  },
  created() {
    this.fetchRooms(); // Fetch rooms when component is created
    this.fetchResidents();
  },
  watch: {
    paymentTerm(newTerm) {
      if (newTerm) {
        this.calculateAmount();
      } else {
        this.amount = 0;
      }
    },
  },
  computed: {
    formattedAmount() {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(this.amount);
    },
  },
};
</script>

<style scoped>
.room-card {
  width: auto;
  height: 450px;
  display: flex;
  flex-direction: column;
  cursor: pointer; /* Make the card look clickable */
  transition: transform 0.2s; /* Smooth hover effect */
}

.room-card:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.lunas-room-header {
  background-color: #4caf50; /* Green for Lunas */
}

.near-due-room-header {
  background-color: #ff9800; /* Yellow for Near Due Date */
}

.due-today-room-header {
  background-color: #ff5722; /* Orange for Due Today */
}

.late-room-header {
  background-color: #d50000;
}

.empty-room-header {
  background-color: #03a9f4; /* Grey for Empty Room */
}

.no-payment-room-header {
  background-color: #757575; /* Grey for Empty Room */
}

.v-row {
  margin: 0 -4px; /* Adjust row margin for compact layout */
}

.v-col {
  padding: 4px; /* Adjust column padding for compact layout */
}

.room-info-row {
  margin-bottom: 10px;
}

.room-number-col {
  flex: 1;
  text-align: left;
}

.room-price-col {
  flex: 1;
  text-align: right;
}
</style> 
